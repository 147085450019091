//Страница Планирования Праздничного меню

import React from 'react';
import { FormHoliday } from '../../molecules/index.js'
import {CurrentUserContext} from '../../../contexts/CurrentUserContext.js';
import {Counter,} from '../../../utils/utils.js';
import {useSelector, useDispatch} from 'react-redux' 
import { Helmet } from 'react-helmet-async';

export function HolidayPlan(props) {
  const [types, setTypes] = React.useState()
  const [category, setCategory] = React.useState([])
  const [title, setTitle] = React.useState('')
  const { currentUser } = useSelector(state => state.currentUser) // данные пользователя
  
 
  const { 
    capacity,
    handleCountClickPlus,
    handleCountClickMinus
  } = Counter({capacity: 1});

    //Добавление типов питания в массив
    const handleCheckBox = (item) => {
      console.log(item)
      setTypes(() => types.map((c) => c.nameEn === item.nameEn ? item : c));
    };

  //Обработка Чекбоксов режима питания
  const handleCheckBoxCategory = (item) => {
    setCategory(() => category.map((c) => c.nameEn === item.nameEn ? item : c)) 
  };

  const handleChangeCounter = (item) => {
    setCategory(() => category.map((c) => c.nameEn === item.nameEn ? item : c)) 
  };

  const handleChangeTitle= (e) => {
    setTitle(e.target.value)
  }
  function handleSubmit(e) {
    e.preventDefault();
    props.onSubmit({title, category, types, capacity})
  }
  return (
    <>
    <Helmet>
      <title>Составить праздничное меню, список продуктов на всех гостей</title>
      <meta name="description" content='Составить праздничное меню на Новый год и День рождения, юбилей или вечеринку'/> 
    </Helmet>
    <FormHoliday
      types={types}
      title={title}
      members={capacity}
      category={category}
      onSubmit={handleSubmit}
      handleChangeTitle={handleChangeTitle}
      handleCountClickPlus={handleCountClickPlus}
      handleCountClickMinus={handleCountClickMinus}
      handleCheckBoxCategory={handleCheckBoxCategory}
      handleChangeCounter={handleChangeCounter}
      handleCheckBox={handleCheckBox}
    />
    </>
  )   
}
