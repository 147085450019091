import React from 'react';
import './MenuWeek.css'; 
import {AddRecipeInMenu} from '../../molecules/index.js'
import { ModalContainer } from '../../atoms/index.js'; 
import {useSelector, useDispatch} from 'react-redux' 
import { getPlan, postPlan, updatePlan, deletePlan, updatePlanFood, getRecipeData} from '../../../store/api.js' 
import { Calendars, ProductList, Settings, PlanSlides } from '../index.js' 
import { OpenModal } from "../../../utils/utils.js"; 
import api from "../../../utils/api.js";
import { Helmet } from 'react-helmet-async';

export function MenuDays(props) {
  const today = new Date();
  const {plans, statusPlan} = useSelector(state => state.plans) // Сохраненные планы с сервера
  const { currentUser } = useSelector(state => state.currentUser) // данные пользователя
  const {recipeDataMenu} = useSelector(state => state.recipes) 
  const dispatch = useDispatch();
  const [choiceDate, setChoiceDate] = React.useState([])
  const [clickCreate, setClickCreate] = React.useState(false) //Отслеживание клика для вывода прелоудера в меню
  const [visibility, setVisibility] = React.useState(false) 
  const [changeMenuDay, setChangeMenuDay] = React.useState(false) //маркер изменения меню для пересчета продуктов
  const [blockedSaveMenu, setBlockedSaveMenu] = React.useState(false) //Просроченная дата или нет 
  const submitDisabled = blockedSaveMenu || statusPlan === 'loading'
  let todayPlan= plans.length > 0 ? plans.filter( i => choiceDate.some( k => i.date === k.date )) : []; 
  let getLocalDate = [] //JSON.parse(localStorage.getItem('datePoint'));
  const { handleClose, setIsOpen, isOpen, hoax, handleHoax } = OpenModal({})
  const [positionMenuItem, setPositionMenuItem] = React.useState({});
 
  React.useEffect(() => {
    if(choiceDate.length === 0 && getLocalDate === null) {
      setChoiceDate([{date: today.setHours(0,0,0,0)}])
    } else if(getLocalDate.length > 0) {
      setChoiceDate(getLocalDate)
    }
  },[])
  // запрос список меню из базы при монтировании страницы
  React.useEffect(() => {
    if(Object.keys(currentUser).length !== 0) {
      dispatch(getPlan(currentUser._id)) 
    }
  },[currentUser])

  React.useEffect(() => {
    if(plans.length > 0) {
      searchPlanData()
    }  
  },[plans, choiceDate]) 

  // Остановка прелоудера при получении данных
  React.useEffect(()=> {
    if(statusPlan === 'resolved') {
      setClickCreate(false)
    }
  }, [statusPlan])
 
  function searchPlanData() {
    let todayMenuBase = plans.filter( i => choiceDate.some( k => i.date === k.date )); //ищем сгенерированные меню на выбранные даты сохраненные в базе
       
    const arrMenu = todayMenuBase.map(item => {
      return [...item.breakfast, ...item.dinner, ...item.supper,...item.snack, ...item.lunch, ...item.lateDinner,]
    })
    const mergedArray = Array.from(new Set(arrMenu.flat()));
    if (recipeDataMenu.length === 0) { 
      // Получение фотографий и данных для отображения рецептов в плане
      dispatch(getRecipeData(mergedArray))
    } else { 
      const news = mergedArray.filter(m=> !recipeDataMenu.find(r=> r._id === m) )
      if(news.length > 0) {
        dispatch(getRecipeData(news))
      }
    }  
  }


                                           /* КАЛЕНДАРЬ*/
  //Обновляет выбранные даты в календаре, если даты старые блокирует изменения,если нет то записывает дату в стэйт
  function handleDateRange(data) {
    setChangeMenuDay(false)
    const blockedSaveMenu = data.some((i)=> {
      return i.date < today.setHours(0,0,0,0)
    })
     // localStorage.setItem('datePoint', JSON.stringify(data));
    setBlockedSaveMenu(blockedSaveMenu)
    setChoiceDate(data)
  } 

  //Видимость календаря при клике
  function handleCalendarVisibility() {
    setVisibility(!visibility)     
  }

  function handleChangeMenuDay() {
    setChangeMenuDay(true)
  }

  function handleChangeMenu() {
    setChangeMenuDay(false)
  }
   
  // Формирование запроса рецептов подходящих user
  function handleGeneration() { 
    setClickCreate(true)
    if(blockedSaveMenu) {
      props.blockedSaveMenu('blockedSaveMenu')
    } else {
      api.getServerMenu(choiceDate)
      .then((exl) => {
        handleChangeMenuDay()
        let todayMenuBase = plans.filter( i => choiceDate.some( k => i.date === k.date )); //ищем сгенерированные меню на выбранные даты сохраненные в базе
        let todayNewMenu = exl.filter( i => todayMenuBase.every( k => k.date !== i.date )) //план на новые даты
        let todayUpdateMenu = exl.filter( i => todayMenuBase.some( k => k.date === i.date )) // план на старые даты
        if(todayMenuBase.length === 0) { 
           dispatch(postPlan(exl))
        } else if (todayMenuBase.length !==0) {
          if(todayNewMenu.length >0) {
            dispatch(postPlan(todayNewMenu)) 
          }
          if(todayUpdateMenu.length > 0) {
            const update = todayUpdateMenu.map(t=> {
              const card = todayMenuBase.find(u=> u.date === t.date)
              return {...t,
              _id: card._id,
              }
            })
            dispatch(updatePlan(update))
          }
        } 
      })
    }
  } 

                                       /* РУЧНАЯ КОРРЕКТИРОВКА МЕНЮ*/ 
                                
  //функция замены плана на день
  function handleAutoReplaceMenu(item) {  
    api.getServerMenu([{date: item.date}])
    .then((newPlane) => { 
      dispatch(updatePlan([{...newPlane[0], _id: item._id}])) 
      handleChangeMenuDay() 
    }) 
  }

   //Удаление из плана меню целого дня
   function handleDeleteMenuDay(id) {
    dispatch(deletePlan(id))
    handleChangeMenuDay()
  }

  //функция добавления приема пищи в план
  function handleAddMeals(meals, item) { 
    let key 
    currentUser.settings.powerMode.map(i=> {
      if(i.nameEn === meals.mealsEn) {
        return key = i.dish[0].nameRu
      }
    }) 
    api.getRecipePlan(key)
    .then((newDish)=> {  
      if(newDish) { 
        const upPlane = Object.assign({}, item, {[meals.mealsEn]: [newDish._id]}); 
        dispatch(updatePlanFood({id: upPlane._id, plan: upPlane}))
        handleChangeMenuDay() 
      } else {
        props.blockedSaveMenu('noSuitableDish')
      }
    }) 
  } 

  //функция удаления рецепта из плана
  function handleDeleteRecipe(id, powerMode, item) {
    const updatePlane = Object.assign({}, item, {[powerMode]: item[powerMode].filter(k=> k !== id)}); 
    dispatch(updatePlanFood({id: updatePlane._id, plan: updatePlane})) 
    handleChangeMenuDay()
  } 

  // Автоматическая замена блюда в плане
  function handleAutoReplace(recipe, mealsEn, mealsRu, item) { 
    api.getRecipePlan(recipe.category)
    .then((newDish)=> { 
      if(newDish) {
        const upPlane = Object.assign({}, item, {[mealsEn]: item[mealsEn].map(k=> k === recipe._id ? newDish._id : k )}); 
        dispatch(updatePlanFood({id: upPlane._id, plan: upPlane}))
        handleChangeMenuDay()
      } else {
        props.blockedSaveMenu('noSuitableDish')
      }
    }) 
  }

                            /* Обработка данных для поиска в модальном окне*/ 
// Передает данные в модалку для замены рецепта
function handleEdite(id, mealsEn, mealsRu, item) {
  setIsOpen(true)
  setPositionMenuItem({
    id,
    powerMode: mealsEn,
    powerModeRu: mealsRu,
    item: item,
    replace: true
  })
} 

//функция замены рецепта в плане
function handleReplace(id, recipe, powerMode, item) {
  const updatePlane = Object.assign({}, item, {[powerMode]: item[powerMode].map(k=> k === id ? recipe._id : k )}); 
  dispatch(updatePlanFood({id: updatePlane._id, plan: updatePlane}))
  handleChangeMenuDay()
}

// Передает входные данные в модалку для добавления рецепта в план
function handleAddMenuItem(mealsRu, mealsEn, item) {
  setIsOpen(true)
  setPositionMenuItem({
    powerMode: mealsEn,
    powerModeRu: mealsRu,
    day: item.date,
    item: item,
    replace: false
  })
} 

 //функция добавления рецепта в план
 function handleAddDish(recipe, powerMode, item) {  
  const updatePlane = Object.assign({}, item, {[powerMode]: [...item[powerMode], recipe._id]});  
  dispatch(updatePlanFood({id: updatePlane._id, plan: updatePlane}))
  handleChangeMenuDay()
}

                               /* ВСПОМОГАТЕЛЬНЫЕ ФУНКЦИИ*/ 

  return (
    <>
     <Helmet>
      <title>Составить меню и план питания самостоятельно, список покупок, калорийность блюд</title>
      <meta name="description" content='Составить план питания для кето диет, составить детское меню, составить меню диет столов. Выберите тип питания, граик питания, калорийность дня итд'/> 
    </Helmet>
    <section className='menu-heading'>
      <h2 className='menu__title font35Bold'>Ваше меню</h2>
      <p className='menu__subtitle font24Bold'>Составьте меню на любое количество дней</p>
      <p className='menu__text font24Norm'>Заполните настройки, выберите даты и кликните создать меню</p>
      {blockedSaveMenu && <p className='menu__disabled font24Norm'>Обновить меню на прошедшие даты нельзя, выберите текущие даты</p>
      }
      <div className='menuweek__control'>
        <button 
          type='button'  
          className={`menuweek-button button__gener font20Norm color`} 
          onClick={props.handleSettings}
        > Настройки
        </button>
        <Calendars 
          userMenu={plans} 
          isOpen={visibility} 
          dateDay={getLocalDate}
          onDayRange={handleDateRange} 
          handleCalendarVisibility={handleCalendarVisibility}
        /> 
        
        <button 
          type='button' 
          disabled={submitDisabled} 
          className={`menuweek-button button__gener font20Norm color ${submitDisabled ? ('form__button_disabled') : ''}`} 
          onClick={handleGeneration}>{`${todayPlan.length > 0 ? 'Обновить меню' : 'Создать меню'}`}
      </button>   
      
      </div>
    </section>
    <section className='table'> 
    <ProductTable
        date={choiceDate} 
        products={todayPlan}
        hoax={hoax}
        setHoax={handleHoax}
        statusPlan={statusPlan}
        addRecipe={props.addRecipe}
        changeMenuDay={handleChangeMenuDay}
        replaceRecipe={props.replaceRecipe}
        clickCreate={clickCreate} 
        blockedSaveMenu={blockedSaveMenu}
        blockedSaveMenus={props.blockedSaveMenu}
        handleDeleteMenuDay={handleDeleteMenuDay}
        handleDeleteRecipe={handleDeleteRecipe}
        handleEdite = {handleEdite}
        handleAddMenuItem={handleAddMenuItem}  
        handleAutoReplace={handleAutoReplace}
        handleAddMeals={handleAddMeals}
        handleAutoReplaceMenu={handleAutoReplaceMenu}
        
      /> 
    </section> 
    {todayPlan.length > 0 &&
        <ProductList 
          date={choiceDate} 
          //handlePrint={handlePrint}
          editFoodList={props.handleEditFoodList}
          handleAddFoodList={props.handleAddFoodList}
          handleCheckProductList={props.handleCheckProductList} 
          menuPlane={todayPlan.length > 0 && todayPlan} 
          changeMenuDay={changeMenuDay} 
          blockedSaveMenu={props.blockedSaveMenu} 
          handleChangeMenu={handleChangeMenu}
        />
      } 
    <AddRecipeInMenu 
      addRecipe={handleAddDish} 
      replaceRecipe={handleReplace} 
      replace={isOpen} 
      onClose={handleClose} 
      closeModal={handleClose} 
      positionMenuItem={positionMenuItem} 
      isOpen={isOpen}
    />
    {Object.keys(currentUser).length !== 0 &&
    <ModalContainer 
      isOpen={props.isOpen}
      onClose={props.onClose}
      title='settings'
    > 
       <Settings type='plans' /> 
    </ModalContainer>
}
    </>
  )
}

const useSortableData = (items, config = null) => {
  const [sortConfig, setSortConfig] = React.useState(config);
  const sortedItems = React.useMemo(() => {
    let sortableItems = [...items];
    if (sortConfig !== null) {
      sortableItems.sort((a, b) => {
        if (a[sortConfig.key] < b[sortConfig.key]) {
          return sortConfig.direction === 'ascending' ? -1 : 1;
        }
        if (a[sortConfig.key] > b[sortConfig.key]) {
          return sortConfig.direction === 'ascending' ? 1 : -1;
        }
        return 0;
      });
    }
    return sortableItems;
  }, [items, sortConfig]);

  return { items: sortedItems, sortConfig };
};

const ProductTable = (props) => {
  const { items, sortConfig } = useSortableData(props.products);
  const [windowWidth, setWindowWidth] = React.useState();
  const [cardsPosition, setCardsPosition] = React.useState(1);
 
  function checkWindowWidth() {
    setTimeout((items) => setWindowWidth(window.innerWidth), 500);
  };

  React.useEffect(()=> {
    window.addEventListener('resize', checkWindowWidth)
    if(window.innerWidth <= 768) {
        setCardsPosition(1)
      } else if(window.innerWidth >= 769) {
        setCardsPosition(1)
      }
      return () => window.removeEventListener('resize', checkWindowWidth);
    }, [windowWidth])
 
  const getClassNamesFor = (name) => {
    if (!sortConfig) {
      return;
    }
    return sortConfig.key === name ? sortConfig.direction : undefined;
  };

  return (
      <div className='menuweek__body'>
          <PlanSlides 
          date={props.date}
          items={items}
          hoax={props.hoax}
          setHoax={props.setHoax}
          handleDeleteRecipe={props.handleDeleteRecipe}
          cardsPosition={cardsPosition}
          handleDeleteMenuDay={props.handleDeleteMenuDay}
          handleAddMenuItem={props.handleAddMenuItem}
          addRecipe={props.addRecipe}
          replaceRecipe={props.replaceRecipe}
          changeMenuDay={props.changeMenuDay}
          ingredients={props.ingredients}
          handleEdite = {props.handleEdite} 
          handleAutoReplace={props.handleAutoReplace}
          handleAddMeals={props.handleAddMeals}
          handleAutoReplaceMenu={props.handleAutoReplaceMenu}
          clickCreate={props.clickCreate} 
          blockedSaveMenu={props.blockedSaveMenu}
          blockedSaveMenus={props.blockedSaveMenus}
        /> 
      </div>
   
  );
};