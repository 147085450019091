import React from 'react';
import {Link} from 'react-router-dom'
import './Promo.css';
import promo from '../../../images/promo.png'

export function Promo(props) {
  const item = props.item
  
  return (
    <section className='promo color'>
      <div className='promo__info'> 
        <h1 className='promo__title font35Bold'>Составьте план питания в&nbsp;несколько кликов бесплатно!</h1>
        <Link to='/signin' className="promo__link font26Norm">ВОЙТИ</Link>
      </div>
       <img src={promo} alt='продукты на неделю' className='promo__image'/>
    </section>
  )  
}