import * as React from 'react';
import { useState, useEffect } from 'react';
import styled from 'styled-components';
import './styles.css';

const Frame = styled.div`
  width: 400px;
  border: 1px solid lightgrey;
  box-shadow: 2px 2px 2px #eee;
`;

const Headers = styled.div`
  font-size: 18px;
  font-weight: bold;
  padding: 10px 10px 5px 10px;
  display: flex;
  justify-content: space-between;
  background-color: #fff;
`;

const Button = styled.div`
  cursor: pointer;
`;

export function SimpleCalendar(props) {
  const DAYS = [31, 28, 31, 30, 31, 30, 31, 31, 30, 31, 30, 31];
  const DAYS_LEAP = [31, 29, 31, 30, 31, 30, 31, 31, 30, 31, 30, 31];
  const DAYS_OF_THE_WEEK = ['Пн', 'Вт', 'Ср', 'Чт', 'Пт', 'Сб', 'Вс'];
  const MONTHS = ['Январь', 'Февраль', 'Март', 'Апрель', 'Май', 'Июнь', 'Июль', 'Август', 'Сентябрь', 'Октябрь', 'Ноябрь', 'Декабрь'];
  
  const today = new Date();
  const [date, setDate] = useState(today);
  const [day, setDay] = useState(date.getDate());
  const [month, setMonth] = useState(date.getMonth());
  const [year, setYear] = useState(date.getFullYear());
  const [startDay, setStartDay] = useState(getStartDayOfMonth(date));//день недели отсчета месяца
  const [dateArray, setDateArray] = React.useState([]);
  const [lastDays, setLastDays] = React.useState([]);
  const [prevDays, setPrevDays] = React.useState([]);
  const firstDay = dateArray.length > 0 && dateArray[0]
  
  //Подсчет количества дней между выбранными датами
  let quonityDay = dateArray.length === 1 ||  dateArray.length===0 ? 
  dateArray.length : dateArray.length === 2 && dateArray[1] > dateArray[0] && Math.ceil((dateArray[1]-dateArray[0]+1)/86400000)

  //создание пустого массива с количеством выбранных дат
  let arr= quonityDay> 0 ? Array.from(Array(quonityDay), () => {
    return { date: 0};
  }) : [];
 
  let dataDate = props.dateDay && arr.length ===0 ? props.dateDay : arr

 //Заполнение массива выбранными датами в мс
  for (let i = 0; i < arr.length; i += 1) {
    arr[i].date = firstDay+i*86400000
  }
 
  //Отправка выбранных дат выше в компонент
  useEffect(() => { 
    props.onDayRange(dataDate) 
  }, [dateArray])


  useEffect(() => {
    setDay(date.getDate());
    setMonth(date.getMonth());
    setYear(date.getFullYear());
    //setStartDay(getStartDayOfMonth(date));
  }, [date]);

  function getStartDayOfMonth(date) {
    const startDate = new Date(date.getFullYear(), date.getMonth(), 7).getDay();
    return startDate
  }

  // Переход к следующему месяцу
  function nextMonth () {
    if ( month === 11 ) {
      setMonth(0);
      setYear(year+1);
    }
  else {
    setMonth(month+1)
  }
};

// Переход к предыдущему месяцу
  function previousMonth() {
  if ( month === 0 ) {
    setMonth(11)
    setYear(year - 1);
  }
  else {
    setMonth (month - 1)
  }
};
  useEffect(() => { 
    showcurr(year, month)
  }, [])
  // Показать текущий месяц
  function showcurr() {
    showMonth(year, month);
  };
  useEffect(() => {
    showMonth(year, month);
  }, [year, month]);

// Показать месяц (год, месяц)
function showMonth(y, m) {
  const d = new Date(y, m, 7)
  // Первый день недели в выбранном месяце 
  const firstDayOfMonth = new Date(y, m, 7).getDay()
  // Последний день выбранного месяца
  const lastDateOfMonth =  new Date(y, m+1, 0).getDate()
  // Последний день предыдущего месяца
  const lastDayOfLastMonth = m == 0 ? new Date(y-1, 11, 0).getDate() : new Date(y, m, 0).getDate();

  let i=1;
  do {
    let dow = new Date(y, m, i).getDay();
     // Начать новую строку в понедельник
    if ( firstDayOfMonth === 0 ) {
      setLastDays([])
    }

    // Если первый день недели не понедельник показать последние дни предыдущего месяца
    if ( i===1 && dow !== 1 ) {
      let k = lastDayOfLastMonth - firstDayOfMonth+1;
      const lastDays =[]
      for(let j=0; j < firstDayOfMonth; j++) {
        lastDays[j] = new Date(y, m-1, k++)
      }
      setLastDays(lastDays)
    }
    // Если последний день месяца не воскресенье, показать первые дни следующего месяца
    if ( i == lastDateOfMonth ) {
      let k=1;
      let j =0
      const prevDays =[]
      for(dow; dow < 7; dow++) {
        prevDays[j++] = new Date(y, m+1, k++)
      }
      if(prevDays.length ===7) {
        setPrevDays([])
      } else {
        setPrevDays(prevDays)
      }  
    }
    i++;
  } while(i <= lastDateOfMonth);
}
  //запись в стейт выбранных дат
  function handleClick(d) {
    if (dateArray.length===1) {
      //если даты выбраны в обратном порядке пересортировка по возрастанию
      setDateArray([...dateArray, d.getTime()].sort(function(a, b) {
        return a - b;
      }))
    } else if(dateArray.length === 2  || dateArray.length === 0) {
    
      setDateArray([d.getTime()])
    }
  }
  
  //Расчет высокосный год или нет
  function isLeapYear(year) {
    return (year % 4 === 0 && year % 100 !== 0) || year % 400 === 0;
  }

  const days = isLeapYear(year) ? DAYS_LEAP : DAYS;

  const daysArr=Array.from(Array(days[month]), (i, index) => {
    return i = new Date(year, month, index+1) 
  })
 
  const fullMonthDay =  [...lastDays, ...daysArr, ...prevDays]

  const Day = (props) => {
    const d =props.d && props.d
    const today = props.today
    const userMenu = props.userMenu
    const isDisabled = today !== undefined && d && (today.getTime() > d.getTime())
    const selected = userMenu.length > 0 && userMenu.some((k)=> {
      return k.date === d.getTime()
    })
  
    function handleClick() {
      props.onClick(props.d)
    }
  
    return (
      <>
      {d > 0 
      ? <p onClick={handleClick} disabled={isDisabled} className={`day ${isDisabled && !selected ?  'disabled' 
      : props.isToday && !selected && props.isSelected === undefined ? 'today'
      : props.isToday && selected && !props.isSelected ? 'today__saveDay' : props.isToday && selected && props.isSelected ? 'today selected'
      : props.isToday || props.isSelected ? 'selected' : selected ? 'saveDay' : ''}`}>{d.getDate()}</p>
      : <strong className='day'>{props.d}</strong>
      }
     </> 
    )
  }
  return (
    <div className={`frame ${props.isOpen ? ('calendar__vicalendar__opensibility') : 'calendar__visibility'}`}>
      <Headers>
        <button className='button__calendar button__calendar_left' onClick={previousMonth} />
        <div>
          {MONTHS[month]} {year}
        </div>
        <button className='button__calendar button__calendar_right'onClick={nextMonth}/>
      </Headers>
      <div className='calendar__body'>
        {DAYS_OF_THE_WEEK.map((d) => (
          <Day key={d} d={d} userMenu={[]}/>
        ))}
        {fullMonthDay.map((i, index) => {
          const selected = dataDate.find((k)=> {
            return k.date === i.getTime()
          })

            return (
              <Day
                key={index}
                d={i}
                today={today}
                isToday={i.getTime() === today.setHours(0,0,0,0)}
                isSelected={selected}
                onClick={handleClick}
                userMenu={props.userMenu}
              >
              </Day>
            );
          })}
      </div>
    </div>
  );
}