import React from 'react';
import { useLocation, Navigate  } from 'react-router-dom';
 
export function ProtectedRoute(props) {
  const loggedIn = props.loggedIn; // your logic here
  const location = useLocation();
  
  if (!loggedIn && props.statusUser === 'rejected') {
    return <Navigate to="/signin" state={{ from: location }} />;
  }

  return props.children;
   
}