//Разметка кол-ва персон

import React from 'react';

export function CheckboxCounter (props) {
  const item =props.item
  const name = item.nameEn;
  const [counter, setCounter] = React.useState(0)

  function handleChange() {
    item.boolean = !item.boolean
    props.onChange(item)
  }
 

 function handleCountClickPlus() {
  setCounter(counter+1) 
  item.counter = counter+1
  props.onChange(item)
}

//счетчик членов семьи в минус
function handleCountClickMinus() {
  if (counter > 0) {
    setCounter(counter-1)
    item.counter = counter-1
    props.onChange(item)
  }
}

  return (
    <div className='checkbox-counter'>
      
      <label className='meals__label' htmlFor={item.nameEn} >
        <input type="checkbox" className='meals__checkbox' onChange={handleChange} checked={item.boolean} id={item.nameEn} name='category'/>
        <span className="visible-checkbox item__link"></span>
      </label>
      <legend  className='form-settings__title'> {item.nameRu}</legend>
      <label className='form-settings__counter'>
        <button type='button' onClick={handleCountClickMinus} className='form-settings__button form-settings__button_type_minus'></button> 
        <input disabled={true} type='text' value={counter || ''} required onChange={props.onChange} className='form-settings__input form-settings__input_members' name='members' id='members'/>
        <button type='button' onClick={handleCountClickPlus} className='form-settings__button form-settings__button_type_plus'></button> 
      </label>
    </div>
  )
}

