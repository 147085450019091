//Компонент  строки модального окна с меню в профиле
import React from 'react';
import {Link} from 'react-router-dom'

export function ProfileItem(props) {
 const item =props.item
 
  return (
  <>
    {item.path === 'signout' ? 
      <Link to={`signin`} className='profile-item font16N chois__link' onClick={props.onSignOut} > 
        {props.item.category}
      </Link>
    :
    <Link to={`/lk/${props.item.path}`} className='profile-item font16N chois__link' onClick={props.onClick} >
      {props.item.category}
    </Link>
    }
  </>
  )
}
