import React from 'react';
import './FormHoliday.css'
import { InputCounter, TypesFood, InputIngredient, Ingredientpoint, CheckboxCounter} from '../../atoms/index.js';
import { Checkbox, RadioBox } from '../index.js'

export function FormHoliday(props) {
 
  return (
    <section className='form-settings'> 
      <article  className='form-settings__block'>
        <h1 className='form-settings__heading'>Настройки праздничного меню</h1>
        <p className='form-settings__text'>Чтобы индивидуально спланировать праздничное меню ответьте на следующие вопросы</p>
        <form className='form-settings__from' onSubmit={props.onSubmit} autoComplete="off">
          <div className='form-settings__level'>
          <fieldset className='add-recipe__fieldset add-recipe__fieldset_name'> 
            <label className='add-recipe__label' htmlFor='title'>
              <input type='text' value={props.title || ''} required onChange={props.handleChangeTitle} placeholder='Название мероприятия' className='add-recipe__input add-recipe__input_title' name='title' id='title'/>
              {props.titleError && <span className="email-error add-form__item-error">{props.titleError}</span>}
            </label>
          </fieldset>
            <fieldset className='form-settings__fieldset form-settings__fieldset__cooking'> 
              <InputCounter
                onChange={props.onChange}
                members={props.members}
                handleCountClickPlus={props.handleCountClickPlus}
                handleCountClickMinus={props.handleCountClickMinus}
                title='Количество гостей'
              />
            </fieldset>
            <fieldset className='form-settings__fieldset form-settings__fieldset__category'> 
            <legend className='form-settings__title'>Какие блюда и в каком количестве вы планируете приготовить?</legend>
            {props.category.length > 0 && //список категорий блюд
                    props.category.map((item, index)=> (
                        <CheckboxCounter 
                          key={index} 
                          item={item} 
                          onChange={props.handleCheckBoxCategory}
                          handleChangeCounter={props.handleChangeCounter}
           
                        />
                    ))
                  }
            </fieldset>
            <fieldset className='form-settings__fieldset form-settings__fieldset_type_ing'>
              <legend className='form-settings__title'>Выберите тип питания</legend>
              <TypesFood handleCheckBox={props.handleCheckBox} types={props.types}/>
            </fieldset> 
          </div>  
          <button type='submit' disabled={props.submitDisabled} className={`my-recipe__add-button item__link form-settings__button_type_submit ${props.submitDisabled ? ('add-form__button_disabled') : ''}`}>Сформировать</button>
        </form>
      </article>
    </section>
  )
}
