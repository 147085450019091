import React from 'react';
import {InputRadiobox} from '../index.js'

export function KitchenwareSetting(props) {
  const item=props.item
  return (
    <li className='recipe__kitchenware_setting_item'>
      <InputRadiobox item={item} onChange={props.handleChange}/>
      <p className={`recipe__kitchenware_icon gg-${item.nameEn} xl-size `} ></p>
    </li>
  )        
}
  