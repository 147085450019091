//Инпуты с возможностью единств выбора

import React from 'react';

export function Radio (props) {
 
  return (
    <div className='form-settings__block_row'>
         <label className='meals__label font20Norm' htmlFor='all'>
      <input type="radio" value={'all' || ''} checked={props.statusRec ==='all' ? true : false}  className='checkbox__input' onChange={props.onChange}  name='recipe' id='all' />
      <span className={`checkbox-click  ${props.fetching === 'loading'  ? 'checkbox__waiting' : 'item__link'}`}></span>
      Все рецепты
    </label>
    <label className='meals__label font20Norm' htmlFor='owner'>
      <input type="radio" value={'owner' || ''} checked={props.statusRec ==='owner' ? true : false} className='checkbox__input' onChange={props.onChange}  name='recipe' id='owner' />
      <span className={`checkbox-click  ${props.fetching === 'loading'  ? 'checkbox__waiting' : 'item__link'}`}></span>
      Мои рецепты
    </label>
    <label className='meals__label font20Norm' htmlFor='like'>
      <input type="radio" value={'like' || ''} checked={props.statusRec ==='like' ? true : false} className='checkbox__input' onChange={props.onChange}  name='recipe' id='like' />
      <span className={`checkbox-click  ${props.fetching === 'loading'  ? 'checkbox__waiting' : 'item__link'}`}></span>
      Избранные рецепты
    </label>
     
    </div>
  )
}
