import React from 'react';
import './ModalInfo.css'
import succesOk from '../../../images/succesOk.png'
import succesError from '../../../images/succesErr.png'

export function ModalInfo (props) {
 const succesIcon = props.icon ? succesOk : succesError
  
  return (
    <div className={`modal modal_type_${props.name} ${props.isOpen ? ('modal_opened') : ''}`} >
      <div className={`modal__container modal__container__type_${props.name}`}>   
        <button onClick={props.onClose} type="button" className="modal__close" aria-label="Закрыть форму"></button>
        <img className="modal__icon" src={succesIcon} alt="иконка подтверждения" />
        <h2 className="modal__title modal__title_modal-info">{props.textError}</h2>
      </div>
    </div>
  )
}
