// Разметка блюда в меню
import React from 'react';
import './MenuDish.css'
import { useSelector } from 'react-redux'
import { Preloader } from '../../organisms/index.js'
import { Link } from 'react-router-dom' 

export function MenuDish (props) {
  const {recipeDataMenu, statusMenu} = useSelector(state => state.recipes) 
  const {statusPlan} = useSelector(state => state.plans) 
  let id= props.item  
  let recipe = recipeDataMenu.find(i=> i._id === id)
  const [click, setClick] = React.useState(false) //Отслеживание клика для вывода прелоудера в меню
 
  React.useEffect(()=> {
    if(statusPlan === 'resolved' ) {
      setClick(false)
  
    }
    if(props.hoax ) {
      setClick(false)
      props.setHoax(false)
    }
  }, [statusPlan, props.hoax]) 
  
  function handleReplace() {
    if(!props.blockedSaveMenu) {
      setClick(true)
      props.autoReplace(recipe, props.mealsEn, props.mealsRu)
    } else {
      props.blockedSaveMenus('blockedUpdateMenu')
    } 
  }

  function handleEdite() {
    if(!props.blockedSaveMenu) {
      setClick(true)
      props.handleEdite(id, props.mealsEn, props.mealsRu)
    } else {
      props.blockedSaveMenus('blockedUpdateMenu')
    } 
  }

  function handleDeleteRecipe() {
    if(!props.blockedSaveMenu) {
      setClick(true)
      props.handleDeleteRecipe(id, props.mealsEn)
    } else {
      props.blockedSaveMenus('blockedUpdateMenu')
    } 
  }
 
  return (
    <>
    {(click || props.click || props.clickCreate) && (statusPlan === 'loading' || statusMenu ===  'loading') 
      ||(recipe === undefined) ?
    <div className='menu-dish'> 
      <Preloader type='menu'/>
      <div className='menu-dish__info'> 
      <div className='dish-sceleton flare'></div>
        <div className='menu-dish__icons_rows'>
          <button  className='gg-replace m-size' onClick={handleReplace}/>
          <button onClick={handleEdite} className='gg-edit m-size'/>
          <p className='menu-dish__cal'>{Math.ceil(recipe && recipe.calories.calories/recipe.servings)} ккал</p>
        </div>
      </div>
      <button onClick={handleDeleteRecipe} className='menu-dish__delete gg-delete m-size' />
    </div>
    :
    <div className='menu-dish'>
      <img  className='menu-dish__image' src={recipe && recipe.image[0] }/> 
      <div className='menu-dish__info'> 
        <Link className='menu-dish__title font16N' target="_blank"  to={`/recipes/${id}`}>{recipe && recipe.name}</Link>
        <div className='menu-dish__icons_rows'>
          <button  className='gg-replace m-size' onClick={handleReplace}/>
          <button onClick={handleEdite} className='gg-edit m-size'/>
          <p className='menu-dish__cal'>{Math.ceil(recipe && recipe.calories.calories/recipe.servings)} ккал</p>
        </div>
      </div>
      <button onClick={handleDeleteRecipe} className='menu-dish__delete gg-delete m-size' />
    </div>
    }
  </>
  )
}