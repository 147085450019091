
import './App.css';
import React from 'react';
import { Route, useNavigate, Routes, useLocation } from 'react-router-dom';
import { Home, Recipes, Profile, RecipeCard, Layout, ProtectedRoute, UpdatePassword, Test} from './component/pages/index.js';
import {Header, Footer, ModalInfo, ModalMenuUpdate, NotFound, ImagePopup, UserCard, MenuDays, UserRecipes, Settings, SceletonSideFilter, HolidayPlan } from './component/organisms/index.js';
import {  errorMessage, authErrors, succesOk, infoMessage} from './constants/constants.js';
import * as auth from "./utils/auth.js";
import InfoReset from './component/pages/Info/InfoReset.js';
import api from "./utils/api.js";
import {useDispatch, useSelector} from 'react-redux'
import { getIngredients, getLibrary, getUser, getUserActivity } from './store/api.js'
import SignUp from './component/pages/SignUp/SignUp.js';
import Login from './component/pages/Login/Login.js';
import Reset from './component/pages/Reset/Reset.js';
import { CurrentUserContext } from "./contexts/CurrentUserContext.js"; 
import { infoDelete } from './store/reguestSlice.js'
import {generateSitemap} from './sitemap.jsx'

function App() {
  const dispatch = useDispatch()
  const navigate = useNavigate(); 
  const location = useLocation();
  const { lib } = useSelector(state => state.lib) 
  const { errorRecipe } = useSelector(state => state.recipe) 
  const errorData = false;
  const { currentUser, loggedIn, statusTextUser, statusUser } = useSelector(state => state.currentUser)
  const [showModal, setShowModal] = React.useState(false);
  const [loading, setLoading] = React.useState(false);
  const [showModalUpdateMenu, setShowModalUpdateMenu] = React.useState(false);
  const [textsucces, setTextsucces] = React.useState('');
  const [iconVisual,setIconVisual] = React.useState(false);
  const selectedCardDel = false
  //const regDate = new Date(currentUser.dateAcces).getTime() 
  const [showImagePopup, setShowImagePopup] = React.useState(''); 
  const [scrollTop, setScrollTop] = React.useState(0);
  const [isOpenSet, setIsOpenSet] = React.useState(false);
  //const {showModal, handleClose, setShowModal } = OpenModal({})
 
 /*//Слушатель скролла
  React.useEffect(() => {
    const handleOnScroll = () => {
      setScrollTop(window.scrollY);
    };
    window.addEventListener("scroll", handleOnScroll);
}, []);*/
  React.useEffect(()=> { 
     dispatch(getIngredients())
     dispatch(getLibrary())
     dispatch(getUser())
     /*api.getSitemap()
     .then(res=> console.log(res))*/
     //dispatch(getUserActivity())
     
  }, [])
  //Обработка запроса инфа
  React.useEffect(() => { 
   if(errorRecipe !== null) {
      setShowModal(true)
      setTextsucces(errorMessage[errorData]) 
      setIconVisual(false)
    } else if(statusTextUser !== '') {
      setShowModal(true)
      setTextsucces(statusTextUser)
      setIconVisual(true)
    }
  }, [errorRecipe, statusTextUser, errorData]);
  

  //Получение токена при каждом мониторовании
  /*React.useEffect(() => {
    dispatch(getUser())
  }, [dispatch]); */

 //Перенаправление при авторизации на страницу
  React.useEffect(() => {
  if (location.pathname === '/signin' || location.pathname === '/signin/standart') {
    navigate('lk/my-menu');
  } else {
    navigate(location.pathname);
  }
}, [loggedIn]);

// Поиск избранных рецептов
  /*React.useEffect(() => {
    if(Object.keys(currentUser).length !== 0) {
      dispatch(getRecipeLike(currentUser._id))
    }
  },[currentUser]) */

  //запрос данных из БД
  

                                             /* АВТОРИЗАЦИЯ, РЕГИСТРАЦИЯ, ВЫХОД */
  //Регистрация пользователя
  function onRegister( name, email, password, subs ) {
    auth.register(name, email, password, subs, lib)
    .then((res) => {
      if(res){
        setShowModal(true);
        navigate('signin');
      }
      //setTimeout(blockSub, 30000)
      setShowModal(true)
      setIconVisual(true)
      setTextsucces(succesOk.signinOk)
    })
  .catch(err => {
    if(err === authErrors.conflictErr) { 
      setShowModal(true)
      setIconVisual(false)
      setTextsucces(errorMessage.emailError)
    } else {
      setShowModal(true)
      setIconVisual(false)
      setTextsucces(errorMessage.registrError)
      
    }
  });
}

  //Вход в профиль
  function onLogin(email,password){
    auth.authorize(email, password)
    .then((res) => {
      dispatch(getUser())
      /*if(new Date().getTime() > regDate && !currentUser.admin && !currentUser.supervisor) {
        blockSub()
      }*/
    })
    .catch(err => {
      if(err === authErrors.unauthorizedErr) { 
        setShowModal(true)
        setIconVisual(false)
        setTextsucces(errorMessage.emailandPasswordError)
      } else {
        setShowModal(true)
        setIconVisual(false)
        setTextsucces(errorMessage.searchError)
      } 
    })
  }

  // Востановление пароля
  function onReset(email){
    setLoading(true)
    auth.reset(email)
    .then((res) => {
      setLoading(false)
      setShowModal(true)
      setIconVisual(true)
      setTextsucces(succesOk.emailSend)
      navigate('/');
    })
    .catch(err => {
      if(err === authErrors.badRequestErr) { 
        setShowModal(true)
        setIconVisual(false)
        setTextsucces(errorMessage.userNotFound)
        setLoading(false)
      } else {
        setShowModal(true)
        setIconVisual(false)
        setTextsucces(errorMessage.searchError)
        setLoading(false)
      } 
    })
  }


  // Замена пароля
  function updatePassword(password, userId, token){
    auth.updatePassword(password, userId, token)
    .then((res) => {
      setLoading(false)
      setShowModal(true)
      setIconVisual(true)
      setTextsucces(succesOk.updatePass)
      navigate('signin');
    })
    .catch(err => {
      if(err === authErrors.badRequestErr) { 
        setShowModal(true)
        setIconVisual(false)
        setTextsucces(errorMessage.deadline)
      } else {
        setShowModal(true)
        setIconVisual(false)
        setTextsucces(errorMessage.searchError)
      } 
    })
  }
  
  //Выход из системы
  function onSignOut(){
    auth.signOut()
     .then(()=> { 
        navigate('signin');
        dispatch(getUser())
    })
    .catch(err => console.log(`Не удалось выйти из системы: ${err}`)) 
  }

   //Поиск Страницы
   function getPage(url){
    api.getPage(url)
     .then((res)=> {
       console.log('ok') 
    })
    .catch(err => {
      console.log(err)
    }) 
  }


  //Изменение доступа к подписке
  /*function blockSub() {
    auth.editContent(currentUser.email, currentUser.name, currentUser.acces)
    .then((res) => {
      setShowModal(true)
      setIconVisual(false)
      setTextsucces(succesOk.subNo)
    })
    .catch(err => {
      if(err === authErrors.conflictErr) { 
        setShowModal(true)
        setIconVisual(false)
        setTextsucces(errorMessage.emailError)
      } else {
        setShowModal(true)
        setIconVisual(false)
        setTextsucces(errorMessage.registrError)
        
      }
    });
  }*/

  function handleEditProfile(email, name, phone, login, acces) { 
    auth.editContent(email, name, phone, login, acces)
    .then((res) => {
      setShowModal(true)
      setIconVisual(true)
      setTextsucces(succesOk.changeInfoUser)
    })
    .catch(err => {
      if(err === authErrors.conflictErr) { 
        setShowModal(true)
        setIconVisual(false)
        setTextsucces(errorMessage.emailError)
      } else {
        setShowModal(true)
        setIconVisual(false)
        setTextsucces(errorMessage.registrError)
        
      }
    });
  }
 
  function handleAddPhoto(photo) {
    auth.editPhoto(photo)
    .then((res) => {
      setShowModal(true)
      setIconVisual(true)
      setTextsucces(succesOk.changeInfoUser)
     // setCurrentUser(res);
    })
    .catch(err => {
      if(err === authErrors.conflictErr) { 
        setShowModal(true)
        setIconVisual(false)
        setTextsucces(errorMessage.emailError)
      } else {
        setShowModal(true)
        setIconVisual(false)
        setTextsucces(errorMessage.registrError)
        
      }
    });
   } 

// Продление подписки со списанием бонусов
function handleExtensionSubs(dateAcces, bonus) {
  api.updateSubsUser(dateAcces, bonus)
  .then((res) => {
      //setCurrentUser(res)
      setShowModal(true)
      setIconVisual(true)
      setTextsucces(succesOk.subOk)
    })
    .catch(err => {
      if(err === authErrors.conflictErr) { 
        setShowModal(true)
        setIconVisual(false)
        setTextsucces(errorMessage.emailError)
      } else {
        setShowModal(true)
        setIconVisual(false)
        setTextsucces(errorMessage.registrError)
        
      }
  })
  .catch(err => console.log(`Не удалось изменить настройки: ${err}`))
}
  
 /* function handleSubmitHolidays(values) {
   console.log(values)
  }*/


  function handlerCloseError() {
    setShowModal(false);
    setShowModalUpdateMenu(false)
    setShowImagePopup(false)
    dispatch(infoDelete())
    setIsOpenSet(false)
  }

  function handleBlockedSaveMenu(info) {
    setShowModal(true)
    setTextsucces(infoMessage[info])
  }
 
  function handleImageError(err) {
    setIconVisual(false)
    setShowModal(true)
    setTextsucces(errorMessage.imageNoFile)
  }

  function handleImageClick(image) {
    setShowImagePopup(image);
  }
 
  function handleSettings() {
    setIsOpenSet(true)
  }

  return (
    <CurrentUserContext.Provider value={currentUser}>
    <div className="page"  >
      <Header loggedIn={loggedIn} onSignOut={onSignOut} scrollTop={scrollTop} /> 
      <Routes>
        <Route path="/" element={<Layout />} >
          <Route index  element={<Home />} />
          <Route path="signup/:subs" element={<SignUp onRegister={onRegister} />} />
          <Route path="signin" element={<Login onLogin={onLogin} loggedIn={loggedIn} />} />
          <Route path="reset" element={<Reset onReset={onReset} loading={loading} />} />
          <Route path="reset/ok" element={<InfoReset />} />
          <Route path="password-reset/:userId/:token" element={<UpdatePassword updatePassword={updatePassword}/>} />
          <Route path="recipes" element={<Recipes loggedIn={loggedIn} onCardClick={handleImageClick} scrollTop={scrollTop} />} />
          <Route path="examp" element={<SceletonSideFilter />} />
          <Route path="recipes/:id" element={<RecipeCard loggedIn={loggedIn} onCardClick={handleImageClick} />} /> 
          <Route path="test" element={<HolidayPlan loggedIn={loggedIn} onCardClick={handleImageClick} />} /> 
          <Route path="lk/" element={
            <ProtectedRoute statusUser={statusUser} loggedIn={loggedIn} >
              <Profile  scrollTop={scrollTop}  
                loggedIn={loggedIn} 
                onAddPhoto={handleAddPhoto} 
                blockedSaveMenu={handleBlockedSaveMenu}
                extensionSubs={handleExtensionSubs}
                imageError={handleImageError}
                //handleSubmitHolidays={handleSubmitHolidays}
                onEditProfile={handleEditProfile}
                onClose={handlerCloseError}
                isOpen={isOpenSet}
                handleSettings={handleSettings}
              />
            </ProtectedRoute>} 
           >
            <Route path='my-info' element={<UserCard loggedIn={loggedIn} onAddPhoto={handleAddPhoto} 
            onEditProfile={handleEditProfile} extensionSubs={handleExtensionSubs}/>} 
          />
          <Route path="my-info/:id" element={<RecipeCard loggedIn={loggedIn} onCardClick={handleImageClick} />} />
          <Route path="settings" element={<Settings/>} />
          <Route path="bonus"  element={<Settings />} />
          <Route path='my-recipes' element={<UserRecipes 
            scrollTop={scrollTop}
            loggedIn={loggedIn}/>} 
          />
          <Route path="my-recipes/:id" element={<RecipeCard loggedIn={loggedIn} onCardClick={handleImageClick} />} />
        <Route path='my-menu' element={<MenuDays 
            handleSettings={handleSettings}
            isOpen={isOpenSet}
            onClose={handlerCloseError} 
            blockedSaveMenu={handleBlockedSaveMenu}
         />} />
            <Route path="my-menu/:id" element={<RecipeCard loggedIn={loggedIn} onCardClick={handleImageClick} />} />
        </Route> 
        <Route path="*" element={<NotFound getPage={getPage} />} />
        </Route>
      </Routes>
      <Footer />
      <ModalInfo isOpen={showModal} textError={textsucces} onClose={handlerCloseError} icon={iconVisual} name="modal-info"/>
      <ModalMenuUpdate 
        isOpen={showModalUpdateMenu} 
        onClose={handlerCloseError} 
        cards={selectedCardDel !==null && selectedCardDel} 
      />
    <ImagePopup onClose={handlerCloseError} name="image" card={showImagePopup !== null && showImagePopup} />
    </div>
    </CurrentUserContext.Provider>
  );
}

export default App;
